<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Oranlar</b-card-title>
          <b-button
            to="interest-rate/add"
            variant="primary"
          ><FeatherIcon icon="PlusIcon" />
            Ekle</b-button>
        </b-card-header>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(created)="data">
              <div>
                <b>Oluşturulma</b>
                <div>
                  <small>{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
                </div>
              </div>
              <div v-if="data.item.modified">
                <b>Güncelleme</b>
                <div>
                  <small class="text-warning">{{ moment(data.item.modified).format('DD.MM.YYYY') }}</small>
                </div>
              </div>
              <div>
                <small class="text-muted">{{ data.item.username }}</small>
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                :to="'/interest-rate/edit/' + data.item.id"
                variant="outline-primary"
                size="sm"
                class="mr-1"
              >
                Güncelle
              </b-button>
              <b-button
                variant="outline-danger"
                size="sm"
                @click="deleteData(data.item.id)"
              >
                Sil
              </b-button>
            </template>
          </b-table></b-card-text>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      search: '',
      fields: [
        {
          key: 'month',
          label: 'Ay',
        },
        {
          key: 'year',
          label: 'Yıl',
        },
        {
          key: 'value',
          label: 'Oran',
        },
        {
          key: 'created',
          label: 'Tarih',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'com_interest_rate.id AS id',
          'com_interest_rate.month AS month',
          'com_interest_rate.year AS year',
          'com_interest_rate.value AS value',
          'com_interest_rate.created AS created',
          'com_interest_rate.modified AS modified',
          'com_user.name AS username',
        ],
        limit: 25,
        start: 0,
        order_by: ['com_interest_rate.year,com_interest_rate.month', 'DESC'],
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['interestRate/getRates']
      return data
    },
    dataCount() {
      return this.$store.getters['interestRate/getRatesCount']
    },
    saveStatus() {
      return this.$store.getters['interestRate/getRateSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('interestRate/ratesList', this.dataQuery)
    },
    deleteData(id) {
      this.$store.dispatch('interestRate/rateDelete', id)
    },
  },
}
</script>

<style scoped>

</style>
